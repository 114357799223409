export default {
    title: "Mais de 1.500 recursos para download",
    placeholder: "¿O que você está procurando?",
    latest: "Itens mais recentes",
    images: "Fotos",
    videos: "Vídeos",
    blogs: "Blogs",
    branding: "Branding",
    catalogs: "Catálogos",
    allBrands: "Todas as Marcas",
    nothingFound: "Nenhum conteúdo de mídia foi encontrado com esta pesquisa",
    recursos: "Recursos",
    marcas: "Marcas",
    filtrar: "Filtro",
    descargar: "Baixar",
};
